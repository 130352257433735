const lang = {
  navbar: {
    title: "InstaVolt",
  },
  searchPage: {
    header: "Welcome to InstaVolt",
    label: {
      search: "Search",
      searchStation: "Search Station",
      logo: "Logo",
      qrCode: "QR Code",
      scanQR: "Click to Scan QR Code",
    },
    placeholder: {
      searchStation: "Please enter code printed on station",
    },
  },
  startCharge: {
    errors: {
      inValidEmail: "Email address is invalid.",
      acceptTnc: "Please accept terms and conditions to continue",
    },
    form: {
      email: "Please enter your email address",
    },
    label: {
      preAuthNote:
        "Please note to validate your payment card a £{preAuthAmount} pre-authorization will be applied to your account and this will be amended to reflect the actual cost on completion of the charge.",
      accept: "Accept",
      tnc: "Terms and Conditions",
      printedCode: "Please enter code printed on station",
    },
    button: { continue: "Continue" },
  },
  cookiePolicy: {
    text: "This website uses cookies to enhance the user experience. By using our site, you acknowledge that you have read and understand our ",
    action: "Cookie Policy",
    iAccept: "I Accept",
  },
  stationDetails: {
    title: "Station Details",
    label: {
      slideToCharge: "Slide to charge",
      address: "Address:",
      cost: "Cost:",
      amenities: "Amenities:",
    },
    error: {
      stationNotFound: "Charge station with provided code not found",
      invalidCode: "Please enter valid station code",
    },
    button: {
      back: "Back",
    },
    modal: {
      header: "Validate Card",
      validateCardMessage:
        "If you are charging at this station, Please enter last four digits of your payment card to verify your session.",
      card_number: "Last 4 Digits of Card",
      submit: "Submit",
      cancel: "Cancel",
    },
  },
  paymentFail: {
    title: "Transaction Failed",
    failureLabel: "Your transaction has failed. Please try again.",
    button: {
      back: "back",
      retry: "Retry",
    },
    common: {
      error:
        "There is an error in processing your request. Please try again later.",
    },
  },
  common: {
    errors: {
      somethingWentWrong:
        "Something went wrong please try again after sometime",
    },
  },
  paymentSuccess: {
    title: "Transaction status",
    amount: "Amount:",
    label: "Success!",
    Successlabel: "Transaction Successful",
    redirectInfo: "You will be redirected to charging screen in few seconds...",
    warningLabel: "Warning",
    unableToProceed: "Unable To Proceed",
  },
  authoriseSession: {
    title: "Authorise Charge Session",
    info: "Please wait while we authorise your charging session...",
    nextSteps: "Next Steps: ",
    plugInVehicle:
      "Wait for the station to ask you to plug-in your vehicle if you have not already done so.",
    chargeSession:
      "Your charging session will commence. Time to get yourself some refreshments, while we get on with charging your vehicle",
    common: {
      error:
        "There is an error in processing your request. Please try again later.",
    },
    unableToConnect:
      "We are unable to connect to charging station at this moment. Please try again.",
  },
  chargingProgress: {
    title: "Charging Progress",
    label: {
      chargeDuration: "Charge Duration",
      energyConsumed: "Energy Consumed",
      chargeLevel: "Charge Level",
      cost: "Cost",
      stopCharge: "Stop Charge",
      min: "min",
      kwh: "kWh",
      percentage: "%",
      stopSession: "Session stopped successfully",
      info: "Waiting for session update...",
      sessionComplete: "Session Complete",
      thankYouMessage:
        "Thank you for charging with Instavolt. Please refer to invoice for final payment value.",
      thankYouForCharging: "Thank you for charging with Instavolt.",
      downloadInvoice: "Download Invoice",
      unableToConnect: `We are unable to connect to the charging station at the moment. If you need to stop the charge please press the "Stop Charge" button on the charge station.`,
      error: "Error",
      waitingMessage: "Please wait while we fetch your payment details...",
      paymentDetails: "Payment Details:",
      paymentFailedTitle: "Payment Failed",
      settlementFailed: `Your pre auth transaction of £ ##PRE_AUTH_AMOUNT## was successful but we are unable to process remaining £ ##SETTLEMENT_AMOUNT## for this charging session from your credit card. Please try again for payment by clicking the "Pay With Different Card" button below.`,
      settlementFailedWithNegativeValue:
        "We couldn't process your payment at this time, please wait a moment and click 'Try Again'.  Or if you prefer to use another card, click 'Pay With Different Card'. Don't worry, if the new card works, nothing will be charged to the first card.",
      paymentFailed: `Your payment of £ ##AMOUNT## for this charging session was unsuccessful. Please try again for payment by clicking the "Pay With Different Card" button below.`,
      amountToPay: "Amount To Pay:",
      payWithDifferentCard: "Pay ##AMOUNT## With Different Card",
      tryAgain: "Try Again",
      totalCost: "Total Cost: ",
      includingCharity: "Including charity of £",
      thankYouWithCharity: `Thank you for charging with Instavolt. We have charged your payment card with £##CHARGE_AMOUNT## amount and you've donated £##CHARITY_AMOUNT## amount.`,
      thankYouWithoutCharity: `Thank you for charging with Instavolt. We have charged your payment card with £##CHARGE_AMOUNT## amount.`,
      tryAgainFailed:
        "We couldn't process your payment at this time, Please pay with another card by clicking the 'Pay With Different Card' button.",
      allPaymentsFailedMessage:
        "We couldn't process your payment at this time.",
    },
  },
  history: {
    receipt: {
      title: "RECEIPT",
      header: {
        date: "Date",
        billing_addresss: "Billing Address",
        note: "Your InstaVolt EV Charging Receipt",
        vat_number: "VAT number",
      },
      content: {
        summary: "A summary of your charging transactions is shown below",
        table: {
          header: {
            station_name: "Station Name",
            start: "Start",
            end: "End",
            usage: "Usage",
            unit: "Unit",
            value: "Value",
            transaction_id: "Transaction ID",
          },
        },
        total_before_vat: "Total before VAT",
        vat: "VAT at",
        total_fee: "Total Fee",
        thanks_message:
          "Thank you for charging on the InstaVolt EV Charging Network",
        vatReceiptNotGenerated:
          "VAT receipt has not been generated yet. Please try again after 24 hours from the time of the charging session. If still unsuccessful then contact our customer support.",
        vatReceiptNotAssociatedWithAcc:
          "VAT receipt has not been associated to your account. Please ensure you are using the correct credentials to view the VAT receipt.",
      },
      footer: {
        title:
          "InstaVolt Limited is a company registered in England and Wales, company number 10484882",
        address:
          "6 Cedarwood, Crockford Lane, Chineham Park, Basingstoke, Hampshire, RG24 8WD",
        telephone: "T",
        email: "E",
        note: "Please quote date of transaction and transaction ID in all correspondence.",
      },
    },
  },
  CharityDonationModal: {
    title: "Charity Donation",
    label: {
      seeMore: "See more",
      note: "Note: ",
      yesDonate: "Yes, donate",
      noThanks: "No, thank you",
    },
  },
};
export default lang;
