export class Common {
  static RegularExpression = class RegularExpression {
    public static readonly AlphaNumericRegex = new RegExp(/^[a-zA-Z0-9]*$/);
    public static readonly EmailRegularExpression = new RegExp(
      /^(?=.{1,64}@)(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(?=.{1,255}$)((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    public static readonly DigitsRegularExpression = new RegExp(/^[0-9]*$/);
  };

  static ConfigurationKey = Object.freeze({
    COOKIE_POLICY_URL: "COOKIE_POLICY_URL",
    WEB_PAY_PRE_AUTH_AMOUNT: "WEB_PAY_PRE_AUTH_AMOUNT",
    WEB_PAY_TERM_AND_CONDITIONS: "WEB_PAY_TERM_AND_CONDITIONS",
    DEFAULT_LOCATION_IMAGE: "DEFAULT_LOCATION_IMAGE",
    WEB_PAY_WEBSOCKET_URL: "WEB_PAY_WEBSOCKET_URL",
    CHARITY_DONATION: "CHARITY_DONATION",
    CHARITY_DONATION_LINK: "CHARITY_DONATION_LINK",
    CHARITY_DONATION_IMAGE: "CHARITY_DONATION_IMAGE",
    CHARITY_DONATION_TEXT: "CHARITY_DONATION_TEXT",
    DONATION_TOPUP_OR_ROUNDUP: "DONATION_TOPUP_OR_ROUNDUP",
    DONATION_VALUE: "DONATION_VALUE",
    WEB_PAY_DONATION_NOTE: "WEB_PAY_DONATION_NOTE",
    DONATION: "DONATION",
  });

  static DonationType = Object.freeze({
    ROUNDUP: "ROUNDUP",
    TOPUP: "TOPUP",
  });
}
