import React, { useState } from "react";
import { FC } from "react";
import { Helmet } from "react-helmet";
import { Formik, Field } from "formik";
import { SearchCodeValidationSchema } from "../../validation/search-page/search-page-validation";
import { AsyncSelectBox } from "../../components/reactstrap-formik";
import SearchPageService from "../../services/search-page/search-page-service";
import StandardResponse from "../../models/standard-response";
import { toast } from "react-toastify";
import QRScanner from "./QRScanner";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import { ICommonProps } from "../../types/propTypes";
import { IStation } from "../../models/search-page";

const searchPageService = new SearchPageService();

const initialValues = {
  unique_code: "",
};

const customStyles = {
  option: (styles, { isDisabled }) => {
    return {
      ...styles,
      color: isDisabled ? "#004687" : "",
      cursor: isDisabled ? "not-allowed" : "default",
    };
  },
};

const SearchStation: FC<ICommonProps> = (props) => {
  const { t } = props;

  const navigate = useNavigate();
  const [showQRScanner, setShowQRScanner] = useState(false);

  const openQRCodeScanner = () => {
    setShowQRScanner(true);
  };

  const handleSelect = (value) => {
    if (value && value.unique_code) {
      navigate(`/charge-station/${value.unique_code}`);
    }
  };

  const handleQRScan = (result) => {
    if (result && result.url) {
      if (validateUrl(result.url)) {
        setShowQRScanner(false);
        navigateToUrl(`${result.url}`);
      } else {
        toast.error("Invalid QR Code");
      }
    } else if (result && result.errorName && result.errorMessage) {
      toast.error(result.errorMessage);
    } else {
      toast.error("Invalid QR Code");
    }
    setShowQRScanner(false);
  };

  const navigateToUrl = (url) => {
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      url = "http://" + url;
    }
    window.location.href = url;
  };

  const validateUrl = (url) => {
    const urlRegex = new RegExp(
      /(?:^|[ \t])((https?:\/\/)?(?:localhost|[\w-]+(?:\.[\w-]+)+)(:\d+)?(\/\S*)?)/gm
    );
    return urlRegex.test(url);
  };

  const loadOptions = React.useRef(
    debounce((code, callback) => {
      getAsyncOptions(code).then((options) => callback(options));
    }, 500)
  ).current;

  const getAsyncOptions = async (code) => {
    let codeList: IStation[] = [];

    const filterInput = {
      unique_code: code,
      limit: 10,
    };
    await searchPageService
      .getCodesList(filterInput)
      .then((res: StandardResponse<IStation[]>) => {
        codeList = res.Data;

        if (codeList && codeList.length >= 10) {
          const messageOption = {
            unique_code: "Continue typing to rectify search",
            isdisabled: true,
          };
          (codeList as any).push(messageOption);
        }
      })
      .catch((m) => {
        if (m.response && m.response.data && m.response.data.message) {
          toast.error(m.response.data.message);
        } else {
          toast.error(m.toString());
        }
      });

    return codeList;
  };

  React.useEffect(() => {
    return () => {
      loadOptions.cancel();
      localStorage.removeItem("tryAgainCount");
      localStorage.removeItem("payWithDifferentCardCount");
    };
  }, [loadOptions]);

  return (
    <>
      <Helmet>
        <title>{t<string>("searchPage.label.searchStation")}</title>
      </Helmet>
      {!showQRScanner && (
        <div
          className="search-page container-fluid p-0"
          style={{ position: "relative" }}
        >
          <div className="row h-100 no-gutters">
            <div className="col-md search-background-block">
              <a
                href="#"
                title={t("searchPage.label.logo")}
                className="instavolt-logo"
              >
                <img
                  src={require("../../assets/images/logo.png")}
                  alt={t("searchPage.label.logo")}
                />
              </a>
            </div>
            <div className="col-md-auto search-section">
              <div className="search-outer">
                <div className="search-contain">
                  <h1 className="title">
                    <img
                      src={require("../../assets/images/logo-icon.png")}
                      alt={t<string>("searchPage.label.logo")}
                    />
                    {t<string>("searchPage.header")}
                  </h1>

                  <Formik
                    initialValues={initialValues}
                    onSubmit={() => {}}
                    validationSchema={SearchCodeValidationSchema()}
                  >
                    {(props) => {
                      const {
                        touched,
                        errors,
                        setFieldTouched,
                        setFieldValue,
                      } = props;

                      return (
                        <form
                          className="form-secondary"
                          onSubmit={(e) => {
                            e.preventDefault();
                          }}
                          autoComplete="off"
                          style={{ paddingTop: "20px" }}
                        >
                          <Field name="unique_code">
                            {({ field }) => (
                              <div>
                                <AsyncSelectBox
                                  {...field}
                                  placeholder={t(
                                    "searchPage.placeholder.searchStation"
                                  )}
                                  className={
                                    Boolean(
                                      touched[field.name] && errors[field.name]
                                    )
                                      ? "form-group is-invalid"
                                      : "form-group"
                                  }
                                  loadOptions={loadOptions}
                                  defaultOptions={[
                                    {
                                      unique_code: t(
                                        "searchPage.placeholder.searchStation"
                                      ),
                                      isdisabled: true,
                                    },
                                  ]}
                                  isMulti={false}
                                  onChange={(value) => {
                                    setFieldValue(field.name, value);
                                    setFieldTouched(field.name, true);
                                    handleSelect(value);
                                  }}
                                  onBlur={() =>
                                    setFieldTouched(field.name, true)
                                  }
                                  styles={customStyles}
                                  getOptionLabel={(opt) => {
                                    if (opt.isdisabled) {
                                      return `${opt.unique_code}`;
                                    } else {
                                      return `${opt.unique_code} - ${opt.physical_reference}`;
                                    }
                                  }}
                                  getOptionValue={(opt) => opt.id}
                                  touched={touched[field.name]}
                                  errors={errors[field.name]}
                                  isClearable={true}
                                  isOptionDisabled={(option) =>
                                    option.isdisabled
                                  }
                                  maxMenuHeight={270}
                                />
                              </div>
                            )}
                          </Field>
                        </form>
                      );
                    }}
                  </Formik>
                  <p className="text-center" style={{ paddingTop: "15px" }}>
                    ----------------------- OR -----------------------
                  </p>
                  <h1 className="title">
                    <img
                      src={require("../../assets/images/qr-code.png")}
                      alt={t("searchPage.label.qrCode")}
                      style={{ height: "100px", cursor: "pointer" }}
                      onClick={() => openQRCodeScanner()}
                      data-testid="qr-image"
                    />
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: 600,
                        cursor: "pointer",
                      }}
                      onClick={() => openQRCodeScanner()}
                      data-testid="qr-span"
                    >
                      {t<string>("searchPage.label.scanQR")}
                    </span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showQRScanner && (
        <QRScanner onScan={(result) => handleQRScan(result)}></QRScanner>
      )}
    </>
  );
};

export default SearchStation;
